<div class="container mt-4">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Profissionais Autônomos</li>
    </ol>
  </nav>

  <div *ngIf="deleting" class="backdrop">
    <div class="spinner-border text-light" role="status">
      <span class="visually-hidden">Salvando as Informações...</span>
    </div>
  </div>

  <h1 *ngIf="!loading">Lista de Profissionais Autônomos</h1>

  <!-- Botão para Criar Novo Usuário -->
  <div class="text-end mb-3" *ngIf="!loading && isAdmin">
    <button class="btn btn-primary" (click)="router.navigate(['/professionals/create'])">
      Criar Novo Profissional
    </button>
  </div>

  <!-- Exibe mensagem de carregamento enquanto os dados estão sendo buscados -->
  <div *ngIf="loading" class="spinner-container gap-2">
    <div class="spinner"></div>
    <span>Carregando profissionais...</span>
  </div>

  <div *ngIf="!loading && professionalsData.length == 0">
    <img src="../../../../../assets/img/folder.png" alt="empty" class="empty" />
    <h4>Nenhum profissional cadastrado.</h4>
    <button class="btn btn-primary" (click)="router.navigate(['/professionals/create'])">
      Criar Novo Profissional
    </button>
  </div>

  <app-zebra-employee-table *ngIf="!loading && professionalsData.length > 0"
                            [headers]="professionalsHeaders"
                            [data]="professionalsData"
                            [itemsPerPage]="5"
                            (edit)="onEdit($event)"
                            (delete)="onDelete($event)"
                            (view)="onView($event)"></app-zebra-employee-table>
</div>
