<div class="container my-5">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Alterar Senha</li>
    </ol>
  </nav>

  <div *ngIf="isSaving" class="backdrop">
    <div class="spinner-border text-light" role="status">
      <span class="visually-hidden">Salvando as Informações...</span>
    </div>
  </div>

  <div id="fullPageContent" class="container my-5">
    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
      <section class="mb-4">
        <h5 class="border-bottom pb-2 mb-5">Alterar Senha do Usuário</h5>
        <div class="d-flex gap-3">
          <div class="input-group mb-3">
            <input [type]="isCurrentPasswordVisible ? 'text' : 'password'" class="form-control" formControlName="currentPassword" placeholder="Senha Antiga">
            <span class="input-group-text">
              <i *ngIf="!isCurrentPasswordVisible" class="fas fa-eye-slash" (click)="toggleCurrentPasswordVisibility()" style="cursor: pointer;"></i>
              <i *ngIf="isCurrentPasswordVisible" class="fas fa-eye" (click)="toggleCurrentPasswordVisibility()" style="cursor: pointer;"></i>
            </span>
          </div>

          <div class="input-group mb-3">
            <input [type]="isNewPasswordVisible ? 'text' : 'password'" formControlName="newPassword" class="form-control" placeholder="Nova Senha">
            <span class="input-group-text">
              <i *ngIf="!isNewPasswordVisible" class="fas fa-eye-slash" (click)="toggleNewPasswordVisibility()" style="cursor: pointer;"></i>
              <i *ngIf="isNewPasswordVisible" class="fas fa-eye" (click)="toggleNewPasswordVisibility()" style="cursor: pointer;"></i>
            </span>
          </div>

          <div class="input-group mb-3">
            <input [type]="isConfirmPasswordVisible ? 'text' : 'password'" formControlName="confirmPassword" class="form-control" placeholder="Repita a Nova Senha">
            <span class="input-group-text">
              <i *ngIf="!isConfirmPasswordVisible" class="fas fa-eye-slash" (click)="toggleConfirmPasswordVisibility()" style="cursor: pointer;"></i>
              <i *ngIf="isConfirmPasswordVisible" class="fas fa-eye" (click)="toggleConfirmPasswordVisibility()" style="cursor: pointer;"></i>
            </span>
          </div>
        </div>
      </section>

      <div class="d-grid gap-2" *ngIf="!isSaving">
        <button type="submit" class="btn btn-primary" [disabled]="!validateFields()">Salvar</button>
      </div>

      <div *ngIf="isSaving" class="spinner-container">
        <div class="spinner"></div>
      </div>
    </form>
  </div>

</div>
